:local {
    .header {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logoContainer {
        max-height: 36px;
        max-width: 260px;
        height: 100%;
        width: auto;
        
        /* Style for the logo */
        * {
            height: inherit;
            width: inherit;
            max-height: 100%;
            max-width: 100%;
            object-fit: contain;
        }
    }
}
