:local {
    .header {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .textContainer {
        max-width: 450;
        width: auto;
        text-align: center;
    }
}
