@import "../../../styles/variables";

:local {

  $modal-margin-top-bottom: 1.75rem;
  $modal-width: 80vw;
  // modal-min-height ensures modal is always at least the viewport height not counting vertical margin so that it centers vertically
  $modal-min-height: calc(100vh - #{$modal-margin-top-bottom} * 2);
  $max-modal-width: 940px;
  $width-cutoff-widescreen: 1175px;
  $width-cutoff-mobile: 992px;

  $modal-margin-mobile-top: 70px;
  $modal-margin-mobile: 0px;
  $modal-width-mobile: 100vw;
  $modal-min-height-mobile: 0px;
  /* modal-translate-mobile ensures either bottom anchoring with viewport height - modal height if modal is smaller than viewport,
  or a fixed top margin if modal bigger than viewport. max wrapped in calc to ensure we use CSS max and not Sass max */
  $modal-translate-mobile: translateY(calc(max(#{$modal-margin-mobile-top}, calc(100vh - 100%))));

  $modal-container-padding: 0px;

  $slide-up-modal-animation-og-margin-top: 100vh;
  $slide-up-modal-animation-final-margin-top: 70px;
  $slide-up-modal-animation-og-margin-bottom: -100vh;
  $slide-up-modal-animation-final-margin-bottom: 0;
  $slide-up-animation: slide-up .4s ease-in 1;

  $markdown-paragraph-margin-bottom: 8px;

  $iframe-width: 100%;
  $iframe-height: 80vh;


  .modalContainer {
    padding: $modal-container-padding!important;
  }

  .rideOctaneModal {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -ms-flex-align: center;
    justify-content: center;
    margin: $modal-margin-top-bottom auto;
    width: $modal-width;
    max-width: $modal-width;
    min-height: $modal-min-height;

    .markdownContent {
      p {
        margin-bottom: $markdown-paragraph-margin-bottom;
      }
    }

    .iframeModalContent {
      width: $iframe-width;
      height: $iframe-height;
    }

    // if mobile, change size, margin and animation
    @media screen and (max-width: $width-cutoff-mobile) {
      margin: $modal-margin-mobile;
      width: $modal-width-mobile!important;
      max-width: $modal-width-mobile;
      min-height: $modal-min-height-mobile;
      transition: none!important;
    }

    @keyframes :local(slide-up) {
      from {
        margin-top: $slide-up-modal-animation-og-margin-top;
        margin-bottom: $slide-up-modal-animation-og-margin-bottom;
      }
      to {
        margin-top: $slide-up-modal-animation-final-margin-top;
        margin-bottom: $slide-up-modal-animation-final-margin-bottom;
      }
    }

    // if mobile, apply animation
    &.openModal {
      @media screen and (max-width: $width-cutoff-mobile) {
        animation: $slide-up-animation;
      }
    }

    // if widescreen, max width at 940px
    @media screen and (min-width: $width-cutoff-widescreen) {
      width: $max-modal-width;
      max-width: $max-modal-width;
    }
  }

  .rideOctaneModalMobile {
    @media screen and (max-width: $width-cutoff-mobile) {
      transform: $modal-translate-mobile!important;
    }
  }

  .rideOctaneModalMobileFixed {
    @media screen and (max-width: $width-cutoff-mobile) {
      position: absolute;
      bottom: 0;
      transform: none!important;
    }
  }
}
