:local {
  .octaneToolbarNavigation {
    min-height: 50px;
    a {
      color: white;
      text-decoration: none;
      &:after {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }
    }
  }
  .navDropdownItems {
    color:black !important;
    width: 300px;
    &:hover {
      background-color: #F8F9FA;
    }
  }
  .caretDown {
    width: 0;
    height: 0;
    margin-left: 10px;
    display: inline-flex;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid white;
  }
}
