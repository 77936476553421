.sideDrawer {
    height: 100%;
    background: white;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    right: 20px;
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;

    a {
        color: black;
        text-decoration: none;
        &:hover {
            color: var(--secondary);
        }
    }
}

.sideDrawer.open {
    overflow: scroll;
    transform: translateX(0);
}

.closeX {
    width: 32px;
    font-size: 32px;
    cursor: pointer;

    &:after {
        content: "\d7";
    }
}