@import "../../styles/variables";

:local {

  .logo {
    max-width: 130px;
  }

  .legalLink {
    white-space: nowrap;
  }

  .disclosureVehiclePage {
    line-height: 2;
    padding-bottom: 5rem;
    margin-top: 15px;
    margin-bottom: 15px;

    p {
      margin-bottom: 8px;
      color: #80A4CF;
    }
  }

  .disclosureGeneric {
    line-height: 2;
    margin-top: 15px;
    margin-bottom: 15px;

    p {
      margin-bottom: 8px;
      color: #80A4CF;
    }
  }

  /*
 Smaller mobile Styles - iphone 5/SE , galaxy s5 etc..
*/
  @media (max-width: 365px) {
    .disclosureVehiclePage {
      padding-bottom: 8rem;
    }
  }
}
